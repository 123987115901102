import React from 'react'
import { Link } from 'gatsby'

const ProjectmanagementIntro = ({ sliderRef }) => {
  return (
    <header ref={sliderRef} className="slider-st valign position-re">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="cont md-mb50">
              <div className="sub-title mb-5">
                <h6>Professional Services</h6>
              </div>
              <h1 className="mb-10 fw-600">Project Management</h1>
         
              <Link
                  to={`/contact/contact-light`}
                className="butn bord curve mt-30"
              >
                <span>Contact Us</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img">
              <img src="/img/slid/002.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom left"></div>
    </header>
  );
};

export default ProjectmanagementIntro